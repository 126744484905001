<template>
	<div
		class="wrap__content"
		v-bind:class="{ 'wrap__content--preloader': !applications || !$store.state.userObject }"
	>
		<v-preloader v-if="!$store.state.userObject" :message="errorMessage"></v-preloader>

		<div class="container" v-if="$store.state.userObject">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['DocumentManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.documents') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('placeholder.search')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
											<div class="table__filter--order">
												<multiselect
													class="select__status"
													v-model="filterCategories"
													:options="orderList"
													label="name"
													:clearOnSelect="false"
													:clear-on-select="false"
													:placeholder="$t('placeholder.choose')"
												>
													<template slot="singleLabel" slot-scope="{ option }">{{
														option.name
													}}</template>
													<template slot="noResult">{{ $t('components.not_found') }}</template>
												</multiselect>
											</div>
										</div>
										<div class="table__filter--right">
											<AddAgreement
												v-if="$store.getters.userPermission(['change_document'])"
												:user-id="$store.state.userObject.id"
												@uploaded="apiGetUserDocumentList"
											/>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:server-items-length="totalItems"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										@update:options="pagination"
									>
										<template v-slot:item.name="{ item }">
											{{ item.name }}
										</template>
										<template v-slot:item.created_at="{ item }">
											{{ item.created_at | formatDate }}
										</template>
										<template v-slot:item.status="{ item }">
											<a
												class="tb__table--status"
												:class="{
													'tb__status--success': item.is_deleted == 0,
													'tb__status--error': item.is_deleted == 1,
												}"
											>
												{{ item.is_deleted == 0 ? $t('status.active') : $t('status.blocked') }}
											</a>
										</template>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<router-link
													v-if="$store.getters.userPermission(['read_document'])"
													:to="'/' + $i18n.locale + '/account/documents/' + item.id"
													class="tb__table--btn tb__table--view"
													v-tooltip.top-center="{
														content: $t('tooltip.look'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
												<a
													:href="'https://api.efish.kz' + item.document"
													target="_blank"
													class="tb__table--btn tb__table--delete"
													v-tooltip.top-center="{
														content: $t('button.download'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.75 11.25v3a1.5 1.5 0 01-1.5 1.5H3.75a1.5 1.5 0 01-1.5-1.5v-3M5.25 7.5L9 11.25l3.75-3.75M9 11.25v-9" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</a>
												<a
													class="tb__table--btn tb__table--delete"
													@click="$modal.show('modal_delete'), (document = item)"
													v-tooltip.top-center="{
														content: $t('tooltip.remove'),
														class: ['tooltip__btn'],
													}"
												>
													<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-bind:svg-inline="''" v-bind:style="'width: 18px'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="red"><path d="M213.9 1.8c-7.8 2.1-15.1 6.6-21.2 13.1-9.6 10.3-11.7 17.7-11.7 41.8V75H60l.2 30.2.3 30.3h391l.3-30.3.2-30.2H331.1l-.3-20.3c-.3-18.3-.6-20.7-2.7-26-4.3-10.9-15-21.4-26.1-25.9C296.7.6 295.2.5 258.5.3c-33.5-.2-38.8 0-44.6 1.5zM293 32.2c7.2 3.8 8 6.4 8 26V75h-90V58.2c0-19.5.8-22.1 8-25.9 4.2-2.3 4.9-2.3 36.9-2.3 31.9 0 32.8.1 37.1 2.2zM93 166.3c0 .2 6.1 69.8 13.5 154.7 8.7 100.3 14 156.3 15 159.6 4.3 14.3 16.7 25.9 32.2 29.9 8.6 2.2 196 2.2 204.6 0 15.5-4 27.9-15.6 32.2-29.9 1-3.3 6.3-59.3 15-159.6 7.4-84.9 13.5-154.5 13.5-154.7 0-.2-73.3-.3-163-.3-89.6 0-163 .1-163 .3zm117.8 157.4l-.3 82.8-14.7.3-14.8.3V241h30l-.2 82.7zm60.2.3v83h-30V241h30v83zm60 0v83.1l-14.7-.3-14.8-.3-.3-82.8-.2-82.7h30v83z"/></g></svg>
												</a>
											</div>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>

									<modal
										class="modal__block modal__block--middle modal__block--delete"
										name="modal_delete"
										:width="550"
										:min-width="550"
										height="auto"
									>
										<div class="modal__block--wrapper">
											<div class="modal__block--close" @click="$modal.hide('modal_delete')">
												<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</div>
											<div class="modal__wrapper">
												<div class="modal__header">
													<div class="modal__title">
														{{ $t('messages.are_you_sure_to_delete_item') }}<br />
														<strong>
															{{ document ? document.name : '' }}
														</strong>
													</div>
												</div>
												<form method="POST" @submit.prevent="deleteDocument">
													<div class="modal__content">
														<div class="modal__form">
															<div class="block__row">
																<div class="block__row--left">
																	<button class="block__item" v-if="document">
																		<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																		{{ $t('button.delete') }}
																	</button>
																</div>
																<div class="block__row--right">
																	<a
																		class="block__item block__item--blue"
																		@click="$modal.hide('modal_delete')"
																	>
																		{{ $t('button.cancel') }}
																	</a>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</modal>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'
import Multiselect from 'vue-multiselect'

import AddAgreement from '@/components/AddAgreement.vue'

export default {
	components: {
		Multiselect,
		AddAgreement,
	},
	data() {
		return {
			document: null,
			applications: null,
			totalItems: null,
			errorMessage: {
				status: null,
				text: null,
				messages: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			filterCategories: this.$t('orders.userList'),
			orderList: this.$t('orders.user'),

			headers: [
				{ text: 'Id', value: 'id' },
				{ text: this.$t('headers.fio'), value: 'name', width: '30vw' },
				{ text: this.$t('headers.register_at'), value: 'created_at' },
				{ text: this.$t('headers.status'), value: 'status' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	props: {
		user: {
			type: Object,
		},
	},
	methods: {
		deleteDocument() {
			api.delete('user/document/' + this.document.id + '/delete').then((response) => {
				if (response.status == 200) {
					this.apiGetUserDocumentList(this.options.page, this.options.itemsPerPage)

					this.blockElemet = null
					this.$modal.hide('modal_delete')
				}
			})
		},
		pagination(options) {
			this.apiGetUserDocumentList(options.page, options.itemsPerPage)
		},
		apiGetUserDocumentList(page = 1, per_page = 10) {
			api
				.get(
					`user/document/list?user_id=${this.$store.state.userObject.id}&page=${page}&per_page=${per_page}`,
				)
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
						this.totalItems = response.data.total
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	computed: {
		filteredlist() {
			return (
				this.applications?.filter((d) => {
					if (
						(d.name ? d.name.toLowerCase().includes(this.filters.search.toLowerCase()) : true) &&
						(this.filterCategories.status == 'all' || d.is_deleted == this.filterCategories.status)
					) {
						return true
					}
				}) || []
			)
		},
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.users_parent'),
			}
		},
		meta: [],
	},
}
</script>
<style>
header .header__row {
	display: flex;
}
</style>
