<template>
	<div>
		<a class="btn--link btn--link--plus !inline-flex" @click="$modal.show('modal_addDocument')">
			{{ $t('button.add_new_contract') }}
			<img src="@/assets/img/icon-plus.svg" />
		</a>

		<modal
			class="modal__block modal__block--table"
			name="modal_addDocument"
			:adaptive="true"
			:width="1100"
			:min-width="1100"
			height="auto"
		>
			<div class="modal__block--wrapper modal__table">
				<div class="modal__table--title">{{ $t('titles.adding_new_contract') }}</div>

				<form method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit">
					<div class="form__line">
						<div class="form__line--left">
							<div class="form__line--title">{{ $t('headers.contract_name') }}</div>
						</div>
						<div class="form__line--right">
							<div class="col-xl-7">
								<div
									class="form__line--input"
									:class="[
										errorMessage.messages && errorMessage.messages.name ? 'input--error' : '',
									]"
								>
									<input
										type="text"
										class="input-linck"
										required=""
										name="name"
										value=""
										:placeholder="$t('headers.name')"
									/>
								</div>
								<div
									class="input-required"
									v-if="errorMessage.messages && errorMessage.messages.name"
								>
									<p v-for="(massage, massageInd) in errorMessage.messages.name" :key="massageInd">
										{{ massage }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="form__line">
						<div class="form__line--left">
							<div class="form__line--title">{{ $t('headers.add_date') }}</div>
						</div>
						<div class="form__line--right">
							<div class="col-xl-5">
								<div
									class="form__line--input"
									:class="[
										errorMessage.messages && errorMessage.messages.document_at
											? 'input--error'
											: '',
									]"
								>
									<input
										type="date"
										class="input-linck"
										required=""
										name="document_at"
										value=""
										:placeholder="$t('headers.add_date')"
									/>
								</div>
								<div
									class="input-required"
									v-if="errorMessage.messages && errorMessage.messages.document_at"
								>
									<p
										v-for="(massage, massageInd) in errorMessage.messages.document_at"
										:key="massageInd"
									>
										{{ massage }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="form__line">
						<div class="form__line--left">
							<div class="form__line--title">
								{{ $t('headers.adding_doc') }}<br />
								(PDF, WORD)
							</div>
						</div>
						<div class="form__line--right">
							<div class="col-xl-7">
								<div
									class="form__line--input"
									:class="[
										errorMessage.messages && errorMessage.messages.document ? 'input--error' : '',
									]"
								>
									<label class="form__line--file" :class="fileDocuments.fileClass">
										<input
											type="file"
											ref="fileDocuments"
											name="fileDocuments"
											accept=".jpeg,.png,.jpg,.pdf,.doc,.docx"
											v-on:change="handleFileUpload()"
										/>
										<div class="form__file" v-if="fileDocuments.fileName">
											<div class="form__file--text">
												{{ fileDocuments.fileName }}
											</div>
										</div>
										<div class="form__file" v-else>
											<div class="form__file--text">{{ $t('site.upload_file') }}</div>
											<div class="form__file--icon">
												<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.167 11.475a3.188 3.188 0 00-1.771-5.808H11.12a4.958 4.958 0 10-8.288 4.887M8.5 8.5v6.375M5.667 12.042L8.5 14.875l2.833-2.833" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</div>
										</div>
									</label>
								</div>
								<div
									class="input-required"
									v-if="errorMessage.messages && errorMessage.messages.document"
								>
									<p
										v-for="(massage, massageInd) in errorMessage.messages.document"
										:key="massageInd"
									>
										{{ massage }}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="form__line">
						<div
							class="form__block--line form__messages"
							v-if="errorMessage.status"
							:class="{
								'form__messages--error': errorMessage.status != 200,
								'form__messages--success': errorMessage.status == 200,
							}"
						>
							<label class="form__block--input">
								<span class="form__block--title">
									{{ errorMessage.text }}
								</span>
							</label>
						</div>
					</div>

					<div class="input__row">
						<button class="input-btn form__line--btn">
							{{ $t('button.save') }}
						</button>
						<a
							class="input-btn form__line--btn-black form__line--btn"
							@click="$modal.hide('modal_addDocument')"
						>
							{{ $t('button.cancel') }}
						</a>
					</div>
				</form>
			</div>
		</modal>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	name: 'AddAgreement',
	props: {
		userId: [String, Number],
	},
	emits: ['uploaded'],
	data() {
		return {
			errorMessage: {
				status: null,
				text: null,
				messages: null,
			},
			fileDocuments: {
				file: null,
				fileName: '',
				fileClass: '',
			},
		}
	},
	methods: {
		onSubmit(evt) {
			this.errorMessage = {
				status: null,
				text: null,
				messages: null,
			}

			const formData = new FormData(evt.target)
			formData.append('user_id', this.userId)
			formData.append('document', this.fileDocuments.file)

			api
				.post('user/document/create', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.errorMessage.status = 200
						this.errorMessage.text = response.data.message
						this.$modal.hide('modal_addDocument')
						this.$emit('uploaded')
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},
		handleFileUpload() {
			this.errorMessage = {
				status: null,
				text: null,
				messages: null,
			}
			if (this.$refs.fileDocuments.files[0].size > 20 * 1000 * 1000) {
				this.errorMessage.messages = {
					document: [this.$t('messages.file_to_big', ['20 Mb'])],
				}
				return
			}
			this.fileDocuments.file = this.$refs.fileDocuments.files[0]
			this.fileDocuments.fileName = this.$refs.fileDocuments.files[0].name
			this.fileDocuments.fileClass = 'add_tourist--success'
		},
	},
}
</script>

<style></style>
