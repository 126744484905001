var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"btn--link btn--link--plus !inline-flex",on:{"click":function($event){return _vm.$modal.show('modal_addDocument')}}},[_vm._v(" "+_vm._s(_vm.$t('button.add_new_contract'))+" "),_c('img',{attrs:{"src":require("@/assets/img/icon-plus.svg")}})]),_c('modal',{staticClass:"modal__block modal__block--table",attrs:{"name":"modal_addDocument","adaptive":true,"width":1100,"min-width":1100,"height":"auto"}},[_c('div',{staticClass:"modal__block--wrapper modal__table"},[_c('div',{staticClass:"modal__table--title"},[_vm._v(_vm._s(_vm.$t('titles.adding_new_contract')))]),_c('form',{attrs:{"method":"POST","enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.contract_name')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"col-xl-7"},[_c('div',{staticClass:"form__line--input",class:[
									_vm.errorMessage.messages && _vm.errorMessage.messages.name ? 'input--error' : '',
								]},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","required":"","name":"name","value":"","placeholder":_vm.$t('headers.name')}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.name)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.name),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.add_date')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
									_vm.errorMessage.messages && _vm.errorMessage.messages.document_at
										? 'input--error'
										: '',
								]},[_c('input',{staticClass:"input-linck",attrs:{"type":"date","required":"","name":"document_at","value":"","placeholder":_vm.$t('headers.add_date')}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.document_at)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.document_at),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(" "+_vm._s(_vm.$t('headers.adding_doc'))),_c('br'),_vm._v(" (PDF, WORD) ")])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"col-xl-7"},[_c('div',{staticClass:"form__line--input",class:[
									_vm.errorMessage.messages && _vm.errorMessage.messages.document ? 'input--error' : '',
								]},[_c('label',{staticClass:"form__line--file",class:_vm.fileDocuments.fileClass},[_c('input',{ref:"fileDocuments",attrs:{"type":"file","name":"fileDocuments","accept":".jpeg,.png,.jpg,.pdf,.doc,.docx"},on:{"change":function($event){return _vm.handleFileUpload()}}}),(_vm.fileDocuments.fileName)?_c('div',{staticClass:"form__file"},[_c('div',{staticClass:"form__file--text"},[_vm._v(" "+_vm._s(_vm.fileDocuments.fileName)+" ")])]):_c('div',{staticClass:"form__file"},[_c('div',{staticClass:"form__file--text"},[_vm._v(_vm._s(_vm.$t('site.upload_file')))]),_c('div',{staticClass:"form__file--icon"},[_c('svg',{attrs:{"width":"17","height":"17","viewBox":"0 0 17 17","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M14.167 11.475a3.188 3.188 0 00-1.771-5.808H11.12a4.958 4.958 0 10-8.288 4.887M8.5 8.5v6.375M5.667 12.042L8.5 14.875l2.833-2.833","stroke":"#fff","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])])])]),(_vm.errorMessage.messages && _vm.errorMessage.messages.document)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.document),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"form__line"},[(_vm.errorMessage.status)?_c('div',{staticClass:"form__block--line form__messages",class:{
							'form__messages--error': _vm.errorMessage.status != 200,
							'form__messages--success': _vm.errorMessage.status == 200,
						}},[_c('label',{staticClass:"form__block--input"},[_c('span',{staticClass:"form__block--title"},[_vm._v(" "+_vm._s(_vm.errorMessage.text)+" ")])])]):_vm._e()]),_c('div',{staticClass:"input__row"},[_c('button',{staticClass:"input-btn form__line--btn"},[_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")]),_c('a',{staticClass:"input-btn form__line--btn-black form__line--btn",on:{"click":function($event){return _vm.$modal.hide('modal_addDocument')}}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }